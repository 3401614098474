<template>
  <div>
    <c-tab
      type="horizon"
      :dense="true"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :height="tabHeight"
    >
      <template v-slot:default="tab">
        <chem-components
          :is="tab.component"
          :param="popupParam"
          @insertPreventiveInfo="updateMode"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
export default {
  name: 'gov-schedule-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        envAirMstPreventiveId: '',
        plantCd: '',
        tabName: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'govScheduleInfo',
      tabItems: [
        { name: 'govScheduleInfo', icon: 'info', label: '대기 방지시설 기본정보', component: () => import(`${'./airMaster03Info.vue'}`) },
      ],
      addTabItems: [
        // { name: 'govScheduleHistory', icon: 'construction', label: '방지시설 부품/이력 관리', component: () => import(`${'./airMaster03Part.vue'}`) },
        { name: 'govScheduleHistory2', icon: 'construction', label: '방지시설 유지보수 이력', component: () => import(`${'./airMaster03History.vue'}`) },
      ]
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(window.innerHeight - 200);
    },
  },
  watch: {
  },
  methods: {
    init() {
      if (this.popupParam.tabName) {
        this.tab = this.popupParam.tabName;
      }
      if (this.popupParam.envAirMstPreventiveId != '') {
        this.tabItems = this.tabItems.concat(this.addTabItems);
      }
    },
    updateMode(envAirMstPreventiveId) {
      this.popupParam.envAirMstPreventiveId = envAirMstPreventiveId;
      this.tabItems = this.tabItems.concat(this.addTabItems);
    },
  }
};
</script>